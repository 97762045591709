import { BrowserModule } from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventListComponent } from './event-list/event-list.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { EventBookComponent } from './event-book/event-book.component';
import { EventOtpComponent } from './event-otp/event-otp.component';
import { PaymentComponent } from './payment/payment.component';
import { EventQrcodeComponent } from './event-qrcode/event-qrcode.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EventDetailComponent,
    EventListComponent,
    ThankYouComponent,
    EventBookComponent,
    EventOtpComponent,
    PaymentComponent,
    EventQrcodeComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
