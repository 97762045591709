
 <header>
  <div class="header-wrapper" id="navbar"> 
    <div class="container">
       <div class="col-md-4 col-sm-4 col-xs-6">
        <a href="home">
        <img src="assets/images/logo.png" class="img-responsive" id="home">
      </a>
     </div>
     <div class="col-md-8 col-sm-8 col-xs-6">
       <nav class="navbar main-navigation">
        <div class="container-fluid">
        <div class="navbar-header">
         <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse" id="myNavbar">
          <ul class="nav navbar-nav">
          <li *ngIf="!isEventPage"><a href="/home#about">About Us</a></li>
          <li *ngIf="!isEventPage"><a href="/home#how-works">How It Works</a></li>
          <li *ngIf="!isEventPage"><a href="/home#why-us">Why Us</a></li> 
          <li *ngIf="!isEventPage"><a href="/home#features">FEATURES</a></li> 
          <li *ngIf="!isEventPage"><a href="/home#pricing">PRICING</a></li> 
          <li *ngIf="isEventPage"><a [routerLink]="['/home']">Home</a></li>
          <li *ngIf="isEventPage"><a [routerLink]="['/events']">Event List</a></li> 
          <li><a href="{{route}}#contact-us">CONTACT US</a></li> 
          </ul>
        </div>
        </div>
      </nav>
     <div>
  </div>
</div>
</div>
</div>
</header>

<router-outlet></router-outlet>
<section class="contact-section text-center" id="contact-us">
  <div class="container">
    <h2 class="main-title white-text">CONTACT US<img src="assets/images/heading-arrow.png"></h2>
    <p>Subscribe to our email newsletter for useful tips and resources. </p>
    <div class="contact-form">
        <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                 <input type="text" class="form-control" placeholder="Full Name" [(ngModel)]="name" name="name"
                 #bname="ngModel">
            </div>
             <div class="form-group col-md-6 col-sm-6 col-xs-12">
                 <input type="email" class="form-control" placeholder="Email" [(ngModel)]="email" name="email">
            </div>
            <div class="form-group col-md-12">
                <textarea class="form-control" placeholder="Message" [(ngModel)]="message" name="message"></textarea>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                <p id="question">{{num1}} + {{num2}} =</p>
                <input type="text" class="form-control captcha-text" [(ngModel)]="captcha" name="captcha">
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12">
                <button type="submit" [disabled]="!contactForm.form.valid" class="btn">SEND NOW</button>
            </div>
        </form>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="col-md-6 col-sm-6 col-xs-12">
       <p>Conference Organizer. &copy; Copyright 2018. All rights reserved. </p>
  </div>
  <div class="col-md-6 col-sm-6 col-xs-12">
    <ul class="social-icons">
       <li><a href="javascript:void(0);"><img src="assets/images/social1.png"></a></li>
     <li><a href="javascript:void(0);"><img src="assets/images/social2.png"></a></li>
     <li><a href="javascript:void(0);"><img src="assets/images/social3.png"></a></li>
    </ul>
  </div>
</div>
</footer>