<section class="icms-events-banner">
    <div class="container">
       <div  class="text-center icms-event-banner-logo">
          <img src="assets/images/events/center-img.png" class="img-responsive"alt="logo">
       </div>
       <h3>cerebrum Infotech event</h3>
    </div>			
</section>
<section class="icms-events-profile">
    <div class="container">
        <div class="icms-events-profile-inner">
            <div class="row">
                <div class="col-md-5">
                   <img src="assets/images/events/side-img.jpg" alt="event" class="img-responsive">
                </div>
                <div class="col-md-7">
                  <h2 class="icms-events-heading">cerebrum Infotech india</h2>
                  <h4>kthakur@seasiainfotech.com</h4>
                  <p>Cerebrum Infotech, a CMMI Level 5 software development company, caters Enterprises as well as startups a dependable, innovative & stable methodology to Design, Develop, Test & Market their solutions.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="icms-events-list">
       <div class="container">
           <div class="text-center">
            <h2 class="main-title">Events List <img src="assets/images/heading-arrow.png"></h2>
           </div>
           <div class="row">
               <div class="col-md-4" *ngFor="let item of events">
                 <div class="icms-events-list-inner">
                     <img src="{{item.eventImage}}" class="img-responsive icms-events-list-image">
                     <div class="icms-events-list-inner-content">
                        <h4>{{item.title}}</h4>
                        <ul class="icms-events-list-date">
                            <li>
                               <i aria-hidden="true" class="fa fa-calendar"></i> {{ item.start_date_time | date }}
                            </li>
                            <li>
                               <i aria-hidden="true" class="fa fa-clock-o"></i> {{item.start_date_time | date : 'hh:mm:ss a' }}
                            </li>
                            <li>
                               <i aria-hidden="true" class="fa fa-map-marker"></i>  {{item.location_name}}
                            </li>
                        </ul>
                        <p> {{item.description}}</p>
                        <a [routerLink]="['/event-detail',item.event_id]" class="btn">Details</a>
                     </div>
                 </div>
               </div>
              
           </div>
       </div>
</section>
