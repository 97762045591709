import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from './../services/event.service';

@Component({
  selector: 'app-event-book',
  templateUrl: './event-book.component.html',
  styleUrls: ['./event-book.component.css']
})
export class EventBookComponent implements OnInit {
  isSelectedAll: boolean = false;
  eventId: number;
  sessions: Array<any> = [];
  event: any;
  ticketnum: number = 1;
  name: string = '';
  email: string = '';
  totalAmount: number = 0;
  selectedSessionIds: Array<string> = [];
  fullticketprice : number;
  fullticket : boolean;
  constructor(private route: ActivatedRoute, private eventService: EventService, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = +(params['eventId']);
    });
    this.GetSessions();
    this.GetEventDetails();
  }

  GetEventDetails() {
    this.eventService.getEvent(this.eventId).subscribe(data => {
      if (data.code == 200) {
        this.event = data.event;
      }
    });
  }

  GetSessions() {
    this.eventService.getSessions(this.eventId).subscribe(data => {
      if (data.code == 200) {
        this.sessions = data.session_list;
      }
    });
  }

  onSelection() {
    this.totalAmount = 0;
    this.selectedSessionIds = [];
    this.sessions.forEach(element => {
      if (element.isSelected) {
        this.selectedSessionIds.push(element._id);
        this.totalAmount += element.ticket_price;
      }
      else{
        this.isSelectedAll = false;
      }
    });
  }


  onSubmit() {
    if (this.selectedSessionIds.length == 0) {
      alert("Please select atleast one session.")
      return false;
    }
    else if (this.name == undefined || this.name == null || this.name.trim() == '') {
      alert("Please fill name.")
      return false;
    }
    else if (this.email == undefined || this.email == null || this.email.trim() == '') {
      alert("Please fill email.")
      return false;
    }
    else if (this.ticketnum <= 0) {
      alert("Please enter number of tickets greater than one.")
      return false;
    }
    else {
      let obj = {
        name: this.name,
        email: this.email,
        event_id: this.eventId,
        event_main_id: this.event._id,
        userImage: null,
        ticket_price: this.totalAmount,
        buy_total_ticket: this.ticketnum,
        mobile: 4543543,
        ticket_type: 2,
        country_code: 91,
        guest_type: 1,
        sessions: this.selectedSessionIds
      }

      // this.eventService.setEventValue(obj);
      // this.sendOTP();

      this.eventService.addGuest(obj).subscribe(data => {
        if (data.code == 200) {
          this.eventService.setEventValue(obj);
          this.sendOTP();
          alert(data.message);
        }
        else {
          alert(data.message);
        }
      });
    }
  }

  sendOTP() {
    let obj = { email: this.email }
    this.eventService.sendOTP(obj).subscribe(data => {
      if (data.code == 200) {
        this.router.navigateByUrl('/event-otp');
      }
    });
  }

  ResetForm() {
    this.ticketnum = 1;
    this.name = '';
    this.email = '';
  }

  onSelectionAll() {
    this.totalAmount = 0;
    this.selectedSessionIds = [];
    if (this.isSelectedAll) {
      this.sessions.forEach(element => {
        this.selectedSessionIds.push(element._id);
        element.isSelected = true;
        this.totalAmount += element.ticket_price;
      });
    }
    else {
      this.totalAmount = 0;
      this.selectedSessionIds = [];
      this.sessions.forEach(element => {
        element.isSelected = false;
      });
    }
  }


}
