<section class="icms-events-banner icms-events-book-banner">
	<div class="container">
	   <div class="text-center icms-event-banner-logo">
		  <img src="assets/images/events/center-img.png" class="img-responsive" alt="logo">
	   </div>
	   <h3>Cerebrum Infotech event</h3>
	</div>
 </section>
<section class="thank-you">
	<div class="container">
		<h2>Thank You!</h2>
		<p>Your request has been submitted successfully. <br> We will contact you shortly.</p>
		
	</div>
</section>