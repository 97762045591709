import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-qrcode',
  templateUrl: './event-qrcode.component.html',
  styleUrls: ['./event-qrcode.component.css']
})
export class EventQrcodeComponent implements OnInit {

  constructor(private eventService : EventService, private router : Router) { }
  ticketObj : any[] = [];
  ngOnInit(): void {
    this.ticketObj = this.eventService.getTicketValue();
    if(this.ticketObj == null){
      this.router.navigateByUrl('/events');
    }
  }

}
