import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from './../services/event.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  eventId: number;
  event: any;

  constructor(private route: ActivatedRoute, private eventService: EventService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.eventId = +(params['eventId']);
    });
    this.GetEventDetails();
  }

  GetEventDetails() {
    this.eventService.getEvent(this.eventId).subscribe(data => {
      if (data.code == 200) {
        this.event = data.event;
      }
    });
  }





}
