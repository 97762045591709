import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  CardNumber: string = '';
  Name: string = '';
  ExpiryMonth: string = '';
  ExpiryYear: string = '';
  CVV: string = '';
  TotalAmount: number = 0;
  months: Array<number> = [];
  years: Array<number> = [];
  eventObj: any;
  constructor(private eventService: EventService, private router: Router) { }

  ngOnInit(): void {
    let currentDate = new Date();
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }
    for (let i = currentDate.getFullYear(); i <= (currentDate.getFullYear() + 10); i++) {
      this.years.push(i);
    }
    this.eventObj = this.eventService.getEventValue();
    if (this.eventObj == null) {
      this.router.navigateByUrl('/events');
    }
    else {
      this.TotalAmount = this.eventObj.ticket_price * this.eventObj.buy_total_ticket;
    }
  }

  onSubmit() {
    let obj = {
      CardNumber: this.CardNumber,
      ExpiryMonth: this.ExpiryMonth,
      ExpiryYear: this.ExpiryYear,
      Name: this.Name,
      CVV: this.CVV
    };

    let buyObj = {
      user_detail: [{ name: this.eventObj.name, email: this.eventObj.email }],
      event_id: this.eventObj.event_id,
      sessions: this.eventObj.sessions,
      event_main_id: this.eventObj.event_main_id,
      ticket_price: this.eventObj.ticket_price,
      buy_total_ticket: this.eventObj.buy_total_ticket,
      ticket_type: 2,
      guest_type: 2
    }

    this.eventService.buyTicket(buyObj).subscribe(data => {
      if (data.code == 200) {
        this.eventService.setTicketValue(data.ticket);
        this.router.navigateByUrl('/event-qrcode');
      }
    })
  }
}
