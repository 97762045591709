import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { EventService } from './services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'icms';
  isEventPage = false;
  route: string;
  name: string = '';
  email: string = '';
  message: string = '';
  captcha : number = null;
  num1 = Math.floor((Math.random() * 10) + 1);
  num2 = Math.floor((Math.random() * 10) + 1);
  constructor(router: Router, location: Location, private eventService : EventService) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
        this.isEventPage = this.route.indexOf('event') > -1 || this.route.indexOf('payment') > -1;
      } else {
        this.route = "home";
      }
    });
  }

  ngOnInit(){
    this.num1 = Math.floor((Math.random() * 10) + 1);
    this.num2 = Math.floor((Math.random() * 10) + 1);
  }

  ResetForm() {
    this.message = '';
    this.name = '';
    this.email = '';
    this.captcha = null;
    this.num1 = Math.floor((Math.random() * 10) + 1);
    this.num2 = Math.floor((Math.random() * 10) + 1);
  }

  onSubmit() {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.name == undefined || this.name == null || this.name.trim() == '') {
      alert("Please fill name.")
      return false;
    }
    else if (this.email == undefined || this.email == null || this.email.trim() == '') {
      alert("Please fill email.")
      return false;
    }
    else if (!this.email.match(mailformat)) {
      alert("Please fill valid email.")
      return false;
    }
    else if (this.message == undefined || this.message == null || this.message.trim() == '') {
      alert("Please fill message.")
      return false;
    }
    else if(this.captcha != (this.num1 + this.num2))
    {
      alert("Please enter correct captcha.")
      return false;
    }

    let obj = {
      message: this.message,
      organiserEmail: "kthakur@seasiainfotech.com",
      userEmail: this.email,
      userName: this.name,
    }
    this.eventService.contact(obj).subscribe(data => {
      if (data.code == 200) {
        alert('Thanks for being in touch, we will get back ASAP');
        this.ResetForm();
      }
      else {
        alert(data.message);
      }
    });
  }

}
