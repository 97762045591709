import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EventListComponent } from './event-list/event-list.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { EventBookComponent } from './event-book/event-book.component';
import { EventOtpComponent } from './event-otp/event-otp.component';
import { PaymentComponent } from './payment/payment.component';
import { EventQrcodeComponent } from './event-qrcode/event-qrcode.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'events', component: EventListComponent },
  { path: 'event-detail/:eventId', component: EventDetailComponent },
  { path: 'event-book/:eventId', component: EventBookComponent },
  { path: 'event-otp', component: EventOtpComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'event-qrcode', component: EventQrcodeComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
