<section class="icms-events-banner icms-events-book-banner">
    <div class="container">
       <div class="text-center icms-event-banner-logo">
          <img src="assets/images/events/center-img.png" class="img-responsive" alt="logo">
       </div>
       <h3>Cerebrum Infotech event</h3>
    </div>
 </section>
 <section class="icms-events-list">
    <div class="container icms-book-now-container">
       <div class="text-center">
          <h2 class="main-title">PAYMENT <img src="assets/images/heading-arrow.png"></h2>
       </div>
       <div class="icms-event-book-form">
          <form (ngSubmit)="onSubmit()" #bookForm="ngForm">
             <div class="form-group">
                <div class="row">
                   <div class="col-md-12">
                      <label>Card number</label>
                   </div>
                   <div class="col-md-7">
                      <input type="text" class="form-control" required [(ngModel)]="CardNumber" name="cardnumber"
                         #bcardnum="ngModel" maxlength="16" required>
                   </div>
                </div>
             </div>
             <div class="form-group">
                <div class="row">
                    <div class="col-md-4">
                        <label>Expiry   </label>
                     </div>
                     <div class="col-md-8">
                        <label>CVV</label>
                     </div>
                   <div class="col-md-2">
                       <select class="form-control" [(ngModel)]="ExpiryMonth" name="month" #bmonth="ngModel" required>
                           <option value="">Select</option>
                           <option *ngFor="let i of months" value="{{i}}">{{i}}</option>
                       </select>
                   </div>
                   <div class="col-md-2">
                    <select class="form-control" [(ngModel)]="ExpiryYear" name="year" #byear="ngModel" required>
                     <option value="">Select</option>
                     <option *ngFor="let i of years" value="{{i}}">{{i}}</option>
                 </select>
                </div>
                   <div class="col-md-3">
                    <input type="password" class="form-control" required placeholder="CVV" [(ngModel)]="CVV" name="cvv"
                       #bcvv="ngModel" maxlength="3">
                 </div>
                </div>
             </div>
             <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                        <label>Name on the Card</label>
                     </div>
                   <div class="col-md-6">
                      <input type="text" class="form-control" required placeholder="Name on the card" [(ngModel)]="Name" name="name"
                         #bname="ngModel">
                   </div>
                </div>
             </div>
             <div class="form-group">
                <div class="row">
                    
                   <div class="col-md-12">
                      <label>TOTAL AMOUNT <span
                            class="icms-book-checkbox-right">{{(TotalAmount)| currency}}</span></label>
                   </div>
                </div>
             </div>
             
             <div class="icms-book-now">
                <button type="submit" [disabled]="!bookForm.form.valid" class="btn">Pay</button>
             </div>
             <br />
           
          
             <div [hidden]="bcardnum.valid || bcardnum.pristine" class="alert alert-danger">
                Card number is required
             </div>
             <div [hidden]="bmonth.valid || bmonth.pristine" class="alert alert-danger">
                Expiry Month is required
             </div>
             <div [hidden]="byear.valid || byear.pristine" class="alert alert-danger">
                Expiry Year is required
             </div>
             <div [hidden]="bcvv.valid || bcvv.pristine" class="alert alert-danger">
                CVV is required
             </div>
             <div [hidden]="bname.valid || bname.pristine" class="alert alert-danger">
                Name is required
             </div>
          </form>
       </div>
    </div>
 
 </section>