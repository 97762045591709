<section class="icms-events-banner icms-events-book-banner">
    <div class="container">
       <div  class="text-center icms-event-banner-logo">
          <img src="images/events/center-img.png" class="img-responsive"alt="logo">
       </div>
       <h3>cerebrum Infotech event</h3>
    </div>			
</section>
<section class="icms-events-list">
   <div class="container icms-book-now-container">
       <div class="icms-event-book-form">
           <form method="post" action="#">
              <div class="form-group">
                  <div class="row">
                     <div class="col-md-12">
                     <label>Please enter the OTP sent on your email id</label>
                      <input type="text" class="form-control" placeholder="OTP" name="otp" [(ngModel)]="otp">
                     </div>
                  </div>
              </div>
              <div class="icms-book-now text-center">
               <button type="button" class="btn" (click)="Verify()">Verify</button>&nbsp;
               <button type="button" class="btn" (click)="Cancel()">Cancel</button>
              </div>
           </form>
       </div>
   </div>
</section>