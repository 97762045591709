import { Component, OnInit } from '@angular/core';
import { EventService } from '../services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-otp',
  templateUrl: './event-otp.component.html',
  styleUrls: ['./event-otp.component.css']
})
export class EventOtpComponent implements OnInit {

  constructor(private eventService: EventService, private router: Router) { }
  otp: string;
  eventObj: any;
  ngOnInit(): void {
    this.eventObj = this.eventService.getEventValue();
    if(this.eventObj == null){
      this.router.navigateByUrl('/events');
    }
  }



  Verify() {
    debugger;
    if(this.otp === undefined  || this.otp == null || this.otp.trim() == '')
    {
      alert("Please fill otp");
      return false;
    }
    let obj = { email: this.eventObj.Email, otp: this.otp }
    this.router.navigateByUrl('/payment');
    // this.eventService.verifyOTP(obj).subscribe(data => {
    //   if (data == 200) {
    //     this.router.navigateByUrl('/payment');
    //   }
    //   else
    //   {
    //     alert(data.message);
    //   }
    // });


  }

  Cancel() {
    this.router.navigateByUrl('/event-book/' + this.eventObj.event_id);
  }


}
