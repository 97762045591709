<section class="icms-events-banner icms-events-book-banner">
    <div class="container">
       <div class="text-center icms-event-banner-logo">
          <img src="assets/images/events/center-img.png" class="img-responsive" alt="logo">
       </div>
       <h3>Cerebrum Infotech event</h3>
    </div>
 </section>
 <section class="icms-events-list">
   <div class="container icms-book-now-container">
       <div class="icms-event-book-form">
        
              <div class="form-group">
                  <div class="row">
                     <div class="col-md-12">
                     <label>Payment was successful. Here is your ticket QR Code</label>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-md-4" *ngFor="let item of ticketObj">
                        <img src="{{item.barCode}}" alt="gallery" class="img-responsive">
                        <p>&nbsp;</p>
                     </div>
                    
                  </div>
              </div>
              
       </div>
   </div>
</section>
