import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventModel } from './../models/event';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public eventSubject: BehaviorSubject<any>;
  public ticketSubject: BehaviorSubject<any[]>;
  constructor(private _http: HttpClient) {
    this.eventSubject = new BehaviorSubject<any>(null);
    this.ticketSubject = new BehaviorSubject<any[]>(null);
  }
  baseUrl = environment.baseUrl;

  getEventValue() {
    return this.eventSubject.value;
  }

  setEventValue(obj) {
    this.eventSubject.next(obj);
  }
  getTicketValue() {
    return this.ticketSubject.value;
  }

  setTicketValue(obj) {
    this.ticketSubject.next(obj);
  }


  getEvents() {
    const options = {
      params: new HttpParams().append('user_id', '1')
    }
    return this._http.get<any>(this.baseUrl + '/event/live', options);
  }

  getEvent(eventId: number) {
    const options = {
      params: new HttpParams().append('event_id', eventId.toString())
    }
    return this._http.get<any>(this.baseUrl + '/event/eventdetails', options);
  }

  getSpeakers(eventId: number) {
    const options = {
      params: new HttpParams().append('event_id', eventId.toString())
    }
    //return this._http.get<EventModel>(this.baseUrl + '/GetEvent', options);
  }

  getSessions(eventId: number) {
    const options = {
      params: new HttpParams().append('event_id', eventId.toString())
    }
    return this._http.get<any>(this.baseUrl + '/session/session_list', options);
  }

  addGuest(obj: any) {
    return this._http.post<any>(this.baseUrl + '/guest/addGuest', obj);
  }

  contact(obj: any) {
    return this._http.post<any>(this.baseUrl + '/guest/contactus', obj);
  }

  sendOTP(obj: any) {
    return this._http.post<any>(this.baseUrl + '/guest/sendOTP', obj);
  }

  verifyOTP(obj: any) {
    return this._http.post<any>(this.baseUrl + '/guest/verifyOTP', obj);
  }

  buyTicket(obj: any) {
    return this._http.post<any>(this.baseUrl + '/guest/buyTicket', obj);
  }

}
