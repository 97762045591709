<section class="icms-events-banner icms-events-banner-details">
   <div class="container">
   </div>
</section>
<section class="icms-events-profile" *ngIf="event !== undefined">
   <div class="container">
      <div class="icms-events-profile-inner" >
         <div class="row">
            <div class="col-md-5 event-detail-profile-logo">
               <img  src="{{event.eventImage}}" alt="event" class="img-responsive">
            </div>
            <div class="col-md-7">
               <h2 class="icms-events-heading" >{{event.title}}</h2>
               <h4><span class="pink-clr">DATE &amp; TIME : </span> {{ event.start_date_time | date }}. {{event.start_date_time | date : 'hh:mm:ss a' }} onwards
               </h4>
               <h4><span class="pink-clr">VENUE: </span> {{event.location_name}}</h4>
               <h4><span class="pink-clr">CONFERENCE TYPE: </span> {{event.eventType}}</h4>
               <h4><span class="pink-clr">CATEGORY : </span> {{event.eventCategory}}</h4>
               <p>{{event.description}}</p>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="icms-events-list" *ngIf="event !== undefined">
   <div class="container">
      <div class="text-center">
         <h2 class="main-title">Image Gallery <img src="assets/images/heading-arrow.png"></h2>
      </div>
      <div class="icms-events-gallery">
         <div class="icms-events-gallery-inner" *ngIf="event.eventImage != null">
            <img src="{{event.eventImage}}" alt="gallery" class="img-responsive">
         </div>
         <div class="icms-events-gallery-inner" *ngIf="event.eventImage2 != null">
            <img src="{{event.eventImage2}}" alt="gallery" class="img-responsive">
         </div>
         <div class="icms-events-gallery-inner" *ngIf="event.eventImage3 != null">
            <img src="{{event.eventImage3}}" alt="gallery" class="img-responsive">
         </div>
         <div class="icms-events-gallery-inner" *ngIf="event.eventImage4 != null">
            <img src="{{event.eventImage4}}" alt="gallery" class="img-responsive">
         </div>
         <div class="icms-events-gallery-inner" *ngIf="event.eventImage5 != null">
            <img src="{{event.eventImage5}}" alt="gallery" class="img-responsive">
         </div>
      </div>
   </div>
</section>
<section class="icms-events-list icms-events-speakers" *ngIf="event !== undefined">
   <div class="container">
      <div class="text-center">
         <h2 class="main-title">SPEAKERS <img src="assets/images/heading-arrow.png"></h2>
      </div>
      <div class="row">
         <div class="col-md-6" *ngFor="let item of event.speaker">
            <div class="icms-events-speakers-inner">
               <div class="icms-events-speakers-inner-left">
                  <img src="{{item.speakerImage}}" alt="speakers" class="img-responsive">
               </div>
               <div class="icms-events-speakers-inner-right">
                  <h4 class="icms-event-designation"><span class="pink-clr">NAME : </span>{{item.name}}</h4>
                  <h4 class="icms-event-designation"><span class="pink-clr">OCCUPATION : </span>{{item.qualification}}</h4>
                  <p>{{item.description}}</p>
                  <ul class="social-icons">
                     <li><a target="_blank" href="{{item.facebook_url}}"><img src="assets/images/social1.png"></a></li>
                     <li><a target="_blank"  href="{{item.twitter_url}}"><img src="assets/images/social2.png"></a></li>
                     <li><a target="_blank" href="{{item.linkedIn_url}}"><img src="assets/images/social3.png"></a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<section class="icms-events-list" *ngIf="event !== undefined">
   <div class="container">
      <div class="text-center">
         <h2 class="main-title">Sessions <img src="assets/images/heading-arrow.png"></h2>
      </div>

      <div class="row">
         <div class="col-md-6" *ngFor="let item of event.speaker">
            <div class="icms-events-session-list">
               <h4 class="icms-event-designation"><span class="pink-clr">TITLE : </span>{{item.name}}</h4>
               <h4 class="icms-event-designation"><span class="pink-clr">DETAILS </span></h4>
               <p>{{item.description}}</p>
               <h4 class="icms-event-designation"><span class="pink-clr">SPEAKERS: </span>{{item.speakers}}</h4>
            </div>
         </div>
       
      </div>
      <div class="icms-book-now text-center">
         <a [routerLink]="['/event-book', eventId]" class="btn">Book Now</a>
      </div>
   </div>
</section>