import { Component, OnInit } from '@angular/core';
import { EventModel } from './../models/event';
import { EventService } from './../services/event.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  public events: Array<EventModel> = [];


  constructor(private eventService: EventService) { }

  ngOnInit(): void {
    this.GetEvents();
  }

  GetEvents() {
    this.eventService.getEvents().subscribe(data => {
      if (data.code == 200) {
        this.events = data.live;
      }
    });
  }



}
