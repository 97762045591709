<section class="icms-events-banner icms-events-book-banner">
   <div class="container">
      <div class="text-center icms-event-banner-logo">
         <img src="assets/images/events/center-img.png" class="img-responsive" alt="logo">
      </div>
      <h3>Cerebrum Infotech event</h3>
   </div>
</section>
<section class="icms-events-list">
   <div class="container icms-book-now-container">
      <div class="text-center">
         <h2 class="main-title">SESSIONS AVAILABLE <img src="assets/images/heading-arrow.png"></h2>
      </div>
      <div class="icms-event-book-form">
         <div class="form-group">
            <div class="icms-book-checkbox">
               <div class="icms-book-checkbox-left">
                  <label><input type="checkbox" (change)="onSelectionAll()" [(ngModel)]="isSelectedAll"
                        >Select All</label>
               </div>
               
            </div>
         </div>
         <div class="form-group" *ngFor="let item of sessions">
            <div class="icms-book-checkbox">
               <div class="icms-book-checkbox-left">
                  <label><input type="checkbox" (change)="onSelection()" [(ngModel)]="item.isSelected"
                        [ngModelOptions]="{standalone: true}"> {{item.title}}</label>
               </div>
               <div class="icms-book-checkbox-right">
                  {{item.ticket_price | currency}}
               </div>
            </div>
         </div>

         <form (ngSubmit)="onSubmit()" #bookForm="ngForm">
            <!-- <div class="form-group">
               <div class="row">
                  <div class="col-md-6">
                     <label><input type="checkbox" [(ngModel)]="fullticket"> Full Ticket</label>
                  </div>
                  <div class="col-md-6">
                     <input type="number" class="form-control" required [(ngModel)]="fullticketprice" name="fullticketprice"
                        #bfullticketprice="ngModel" min="1">
                  </div>
               </div>
            </div> -->
            <div class="form-group">
               <div class="row">
                  <div class="col-md-12">
                     <label>NO. OF TICKETS</label>
                  </div>
                  <div class="col-md-12">
                     <input type="number" class="form-control" required [(ngModel)]="ticketnum" name="ticketnum"
                        #bticketnum="ngModel" min="1">
                  </div>
               </div>
            </div>
            <div class="form-group">
               <div class="row">
                  <div class="col-md-6">
                     <input type="text" class="form-control" required placeholder="Name" [(ngModel)]="name" name="name"
                        #bname="ngModel">
                   
                  </div>
                  <div class="col-md-6">
                     <input type="email" class="form-control" required placeholder="Email" [(ngModel)]="email" email
                        name="email" #bemail="ngModel">
                   
                  </div>
               </div>
            </div>
            <div class="form-group">
               <div class="row">
                  <div class="col-md-12">
                     <label>TOTAL AMOUNT <span
                           class="icms-book-checkbox-right">{{(totalAmount * ticketnum)| currency}}</span></label>
                  </div>
               </div>
            </div>
            
            <div class="icms-book-now">
               <button type="submit" [disabled]="!bookForm.form.valid" class="btn">Book Now</button>
            </div>
            <br />
          
         
            <div [hidden]="bticketnum.valid || bticketnum.pristine" class="alert alert-danger">
               No. of tickets is required
            </div>
            <div [hidden]="bname.valid || bname.pristine" class="alert alert-danger">
               Name is required
            </div>
            <div [hidden]="bemail.valid || bemail.pristine" class="alert alert-danger">
               Email is required and should be valid
            </div>
         </form>
      </div>
   </div>

</section>