<section class="slider">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
     <!-- Indicators -->
     <ol class="carousel-indicators">
       <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
       <li data-target="#myCarousel" data-slide-to="1"></li>
       <li data-target="#myCarousel" data-slide-to="2"></li>
       <li data-target="#myCarousel" data-slide-to="3"></li>
       <li data-target="#myCarousel" data-slide-to="4"></li>
       
     </ol>

     <!-- Wrapper for slides -->
     <div class="carousel-inner">
       <div class="item active">
         <img src="assets/images/slider1.jpg" alt="Chania" class="img-responsive">
         <div class="carousel-caption">
           <h3>&#34; Let&#39;s manage your conference smartly &#34;</h3>
           <h2>Nice to meet you.</h2>
         </div>
       </div>

       <div class="item">
         <img src="assets/images/slider2.jpg" alt="Chicago" class="img-responsive">
         <div class="carousel-caption">
           <h3>&#34; Let&#39;s manage your conference smartly &#34;</h3>
           <h2>Nice to meet you.</h2>
         </div>
       </div>
       <div class="item">
         <img src="assets/images/slider3.jpg" alt="Chicago" class="img-responsive">
         <div class="carousel-caption">
           <h3>&#34; Let&#39;s manage your conference smartly &#34;</h3>
           <h2>Nice to meet you.</h2>
         </div>
       </div>
       <div class="item">
         <img src="assets/images/slider4.jpg" alt="Chicago" class="img-responsive">
         <div class="carousel-caption">
           <h3>&#34; Let&#39;s manage your conference smartly &#34;</h3>
           <h2>Nice to meet you.</h2>
         </div>
       </div>
       <div class="item">
         <img src="assets/images/slider5.jpg" alt="Chicago" class="img-responsive">
         <div class="carousel-caption">
           <h3>&#34; Let&#39;s manage your conference smartly &#34;</h3>
           <h2>Nice to meet you.</h2>
         </div>
       </div>
     </div>

     <!-- Left and right controls -->
     <a class="left carousel-control" href="#myCarousel" data-slide="prev">
       <span class="glyphicon glyphicon-chevron-left"></span>
       <span class="sr-only">Previous</span>
     </a>
     <a class="right carousel-control" href="#myCarousel" data-slide="next">
       <span class="glyphicon glyphicon-chevron-right"></span>
       <span class="sr-only">Next</span>
     </a>
   </div>
</section>

<section id="about" class="about-section text-center">
    <div class="container">
        <h2 class="main-title">About Us <img src="assets/images/heading-arrow.png"></h2>
        <p>A smart software to manage all aspects of your conferences using latest technology like Big Data analysis, Artificial Intelligence, BLE devices and various other Sensors. From creating branded conference websites to detailed registration forms. From building delegate agendas to managing online payments. From badges and barcode scanning to in-depth analysis on attendance and revenue.</p>
        <p>Conference planning is stressful enough, with a variety of challenges that impact your planning efforts. The great news is that with iCMS you can solve majority of the issues and smartly achieve your conference goals.</p>
        <img src="assets/images/about-border.png" class="img-responsive about-border">
        <img src="assets/images/about-image.png" class="img-responsive about-image">
    </div>
</section>
<section class="how-works py-55" id="how-works">
    <div class="container">
           <h2 class="main-title white-text">HOW IT WORKS <img src="assets/images/heading-arrow.png"></h2>
           <p>Just follow few simple steps to setup a conference and smartly manage it with real-time data analysis:</p>
           <div class="organizing-section  py-55">
               <h2 class="main-title white-text">ORGANIZING</h2>
               <div class="organizing-slider">
                   <div class="row">
                       <div class="col-md-4 col-sm-4 col-xs-12 ">
                        <img src="assets/images/organizing1.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/organizing2.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/organizing3.png" class="img-responsive">
                       </div>
                   </div>
                 
               </div>
           </div>
           <div class="organizing-section">
               <h2 class="main-title white-text">REALTIME STATS</h2>
               <div class="organizing-slider">
                   <div class="row">
                       <div class="col-md-4 col-sm-4 col-xs-12 ">
                        <img src="assets/images/realtime1.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/realtime2.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/realtime3.png" class="img-responsive">
                       </div>
                   </div>
               </div>
           </div>
           <div class="organizing-section py-55 pb-0">
               <h2 class="main-title white-text">ATTENDEES APP</h2>
               <div class="organizing-slider">
                  <div class="row">
                       <div class="col-md-4 col-sm-4 col-xs-12 ">
                        <img src="assets/images/app1.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/app2.png" class="img-responsive">
                       </div>
                       <div class="col-md-4 col-sm-4 col-xs-12">
                        <img src="assets/images/app3.png" class="img-responsive">
                       </div>
                   </div>
               </div>
           </div>
    </div>
 </section>
 



 <section id="why-us" class="about-section text-center">
    <div class="container">
        <h2 class="main-title">WHY US <img src="assets/images/heading-arrow.png"></h2>
        <p> One software suit to manage all aspects of a conference from planning, to successful execution.  </p>
        <div class="row whyus-inner">
            <div class="col-md-6 col-sm-6 col-xs-12">
                 <img src="assets/images/whyusimage.png" class="img-responsive">
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <ul class="about-list">
                    <li>
                          <img src="assets/images/conference_setup.png" class="aboutlist-icon">
                          <h2>Conference setup:</h2>
                          <p>Easy and intelligent setup using handy mobile App.</p>
                    </li>
                    <li>
                          <img src="assets/images/why1.png" class="aboutlist-icon">
                          <h2>Smart Tracking: (City check-ins)</h2>
                          <p>Smart Tracking is a feature based on Artificial Intelligence that allows the organizer to keep track of the Attendees that have reached the city prior to conference.</p>
                    </li>
                    <li>
                          <img src="assets/images/why2.png" class="aboutlist-icon">
                          <h2>Indoor/Outdoor Navigation:</h2>
                          <p>Best in class Navigation system based on Beacons/GPS. It enables you to guide your visitors, customers and partners inside your premises from their position to a specific point of interest, room or place.</p>
                    </li>
                    <li>
                          <img src="assets/images/why3.png" class="aboutlist-icon">
                          <h2>Real-time Foot-fall/Heat maps</h2>
                          <p>By capturing, tracking and reporting on a rich set of real time data, it offers highly valuable insights to help you make more informed decisions.</p>
                    </li>
                    <li>
                          <img src="assets/images/why4.png" class="aboutlist-icon">
                          <h2>Efficient Solution for your Conferences:</h2>
                          <p>Your time is important, that&#39;s why we have smartly designed this solution to help you save time while planning your conferences, allowing you to manage amazing experiences before, during, and after your conferences.</p>
                    </li>
                    <li>
                          <img src="assets/images/why5.png" class="aboutlist-icon">
                          <h2>Conference Security:</h2>
                          <p>Manage security and anonymity with our privacy features. Define specific access rights for different users to manage security and restrict them from attending unbooked sessions.</p>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="features-secton text-center" id="features">
    <div class="container">
         <h2 class="main-title">Exceptional features <img src="assets/images/heading-arrow.png"></h2>
         <p> Here are the features that will make your business easier  </p>
         <div class="features-inner row">
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/live_streaming.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>Live Media:</h2>
                      <p>Access to Event Gallery including videos, audios and assets/images.
                      </p>
                   </div>
               </div>
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/features2.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>Dedicated mobile Apps:</h2>
                      <p>Dedicated mobile apps for Organizer, Speakers, Attendees & Vendors.
                      </p>
                   </div>
               </div>
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/features3.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>Beacon-based navigation:</h2>
                      <p>Indoors; GPS navigation may fail, but our intelligent Beacon based nav system will assist you to reach your desired destination without any complications.
                      </p>
                   </div>
               </div>
         </div>
         <div class="features-inner row">
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/features4.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>Instant Feedback:</h2>
                      <p>Gather real-time insights and feedbacks from the participants during live sessions.
                      </p>
                   </div>
               </div>
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/features5.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>Custom Notification</h2>
                      <p>Broadcast new updates using real time push notifications to your attendees. 
                      </p>
                   </div>
               </div>
               <div class="col-md-4 col-sm-12">
                   <div class="col-md-3  col-sm-3 col-xs-12">
                      <div class="features-icon">
                      <img src="assets/images/SOS.png" class="img-responsive">
                      </div>
                   </div>
                   <div class="col-md-9 col-sm-9 colxs-12">
                      <h2>SOS</h2>
                      <p>In case of an emergency it can send an emergency sms to the organizer and your selected contacts.
                      </p>
                   </div>
               </div>
         </div>
    </div>
 </section>
 <section class="pricing-secton text-center" id="pricing">
    <div class="container">
         <h2 class="main-title">Pricing<img src="assets/images/heading-arrow.png"></h2>
            <p>Contact Us For Pricing Details:</p>
            <p>sales@icms.live</p>
             <div class="pricing-tables">
                
                     <div class="col-md-3 col-sm-6 p-0">
                        <div class="pricing-tablecontent">
                            <div class="pricing-header">
                                <span>Features</span>
                                
                            </div>
                            <div class="pricing-body">
                               <ul>
                                  <li><img src="assets/images/arrowright.png" alt="angle-right">Create  Conference</li>
                                  <li><img src="assets/images/arrowright.png" alt="angle-right">Web & Native (Android & iOS) Mobile App for
Conference Organizer</li>
                                  <li><img src="assets/images/arrowright.png" alt="angle-right">Dedicated Mobile Apps for Attendees and
Speakers</li>
                                     
                                      <li><img src="assets/images/arrowright.png" alt="angle-right">Send Real Time Notifications</li>
                                      <li><img src="assets/images/arrowright.png" alt="angle-right">Real-Time Statistics</li> 
                                      <li><img src="assets/images/arrowright.png" alt="angle-right">Beacon Based Indoor Navigation</li>
                                      <li><img src="assets/images/arrowright.png" alt="angle-right">Heat Maps</li>
                                      <li><img src="assets/images/arrowright.png" alt="angle-right">Export Data</li>
                               </ul>
                            </div>
                        </div>
                     </div>
                     <div class="col-md-3 col-sm-6 p-0">
                        <div class="pricing-tablecontent">
                            <div class="pricing-header pricing-headerborder green">
                               Trial
                                <span>free</span>
                                
                            </div>
                            <div class="pricing-body pricing-body2">
                               <ul>
                                  <li class="orange">Create 1 <br> Conference</li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  
                                  <li><img src="assets/images/minus-priciing.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/minus-priciing.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/minus-priciing.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/minus-priciing.png" alt="Price Correct"></li>
                               </ul>
                               <!--<div class="pricing-button green">
                                    <button type="button">BUY NOW</button>
                               </div>-->
                            </div>
                        </div>
                     </div>
                     <div class="col-md-3 col-sm-6 p-0">
                        <div class="pricing-tablecontent">
                            <div class="pricing-header pricing-headerborder pink">
                               Pay Per Conference
                                <span><i class="fa fa-rupee"></i> 10,000</span>
                                
                            </div>
                            <div class="pricing-body pricing-body2">
                               <ul>
                                  <li class="orange">Create 1 <br> Conference</li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                 
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                               </ul>
                              <!-- <div class="pricing-button pink">
                                    <button type="button">BUY NOW</button>
                               </div>-->
                            </div>
                        </div>
                     </div>
                     <div class="col-md-3 col-sm-6 p-0">
                        <div class="pricing-tablecontent">
                            <div class="pricing-header pricing-headerborder blue">
                               Yearly Subscription
                               <span><i class="fa fa-rupee"></i> 60,000</span>
                                
                            </div>
                            <div class="pricing-body pricing-body2">
                               <ul>
                                  <li class="orange  orangle-lastbg">Create Unlimited <br> Conferences</li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                 
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                                  <li><img src="assets/images/price-correct.png" alt="Price Correct"></li>
                               </ul>
                               <!--<div class="pricing-button blue">
                                    <button type="button">BUY NOW</button>
                               </div>-->
                            </div>
                        </div>
                     </div>
                     
                     
             
             </div>
         </div>
 </section>
 